import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { actions as authActions } from "../../reducers/signupReducer";
import { getCurrentUser } from "../../selectors/selectors";

function HeaderTopMenu() {
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const GoLogout = () => {
    localStorage.removeItem("token");
    dispatch(authActions.logoutUser());
    navigate("/offers");
  };
  return (
    <>
      <nav className="top-menu">
        <ul className="menu-main">
          <li className="right-item">
            <Link to="/tutorials">Мои курсы</Link>
          </li>
          <li className="right-item">
            <Link to="/offers">Каталог курсов</Link>
          </li>

          {currentUser.username ? (
            <li className="right-item">
              Пользователь <b>{currentUser.username}</b>
              <button onClick={GoLogout} className="button">
                Logout
              </button>
            </li>
          ) : (
            <>
              <li className="right-item">
                <Link to="/login/">Войти</Link>
              </li>

              <li className="right-item">
                <Link to="/signup/">Зарегистрироваться</Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </>
  );
}

export default HeaderTopMenu;
