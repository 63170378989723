import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { FC, useEffect, useState } from "react";
import { actions as programActions } from "../../reducers/programReducer";
import { getModalIsActive } from "../../selectors/selectors";
import { useDispatch, useSelector } from "react-redux";
import Confirmation from "./Confirmation";

type ShowTaskInModalPropsType = {
  show: boolean;
  setShow: (a: boolean) => void;
};
export const ShowConfirmInModal: FC<ShowTaskInModalPropsType> = ({
  show,
  setShow,
}) => {
  const dispatch = useDispatch();

  const handleCancelClose = () => {
    dispatch(programActions.setModalIsActive(false));
    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleCancelClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Подтверждение заказа</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Confirmation />
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="primary" onClick={handleSaveClose}>
            Вперед!
          </Button>

          <Button variant="secondary" onClick={handleCancelClose}>
            Отмена
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};
