import { TutorialType, ProfileType } from "../types/types";
import { InferActionTypes } from "../reduxStore";

const payment = {
  id: "292ebea6-000f-5000-8000-12e23489cced",
  status: "pending",
  paid: false,
  amount: {
    value: "2.00",
    currency: "RUB",
  },
  confirmation: {
    type: "redirect",
    return_url: "https://puhliykrolik.ru/gotpayment",
    confirmation_url:
      "https://yoomoney.ru/checkout/payments/v2/contract?orderId=292ebb49-000f-5000-a000-1a7eec099d08",
  },
  created_at: "2021-11-23T08:34:17.738Z",
  metadata: {},
  payment_method: {
    type: "bank_card",
    id: "292ebb49-000f-5000-a000-1a7eec099d08",
    saved: false,
  },
  recipient: {
    account_id: "843645",
    gateway_id: "1898705",
  },
  refundable: false,
  test: false,
};
type PaymentType = typeof payment;

const defaultstate = {
  confirmationUrl: "",
  returnUrl: "",
  id: "",
  status: "",
  checkedStatus: "",
};

const confirmPayment = {
  amount: { value: "", currency: "" },
  currency: "",
  value: "",
  cancellation_details: {
    party: "",
    reason: "",
  },
  party: "",
  reason: "",
  created_at: "",
  id: "",
  metadata: {},
  paid: false,
  payment_method: {
    type: "",
    id: "",
    saved: false,
  },
  saved: false,
  type: "",
  recipient: { account_id: "", gateway_id: "" },
  account_id: "",
  gateway_id: "",
  refundable: false,
  status: "",
  test: false,
};

type ConfirmPaymentType = typeof confirmPayment;

export const paymentReducer = (state = defaultstate, action: ActionTypes) => {
  let newState = { ...state };
  switch (action.type) {
    case "GETPAYMENT":
      newState.confirmationUrl = action.payment.confirmation.confirmation_url;
      newState.returnUrl = action.payment.confirmation.return_url;
      newState.id = action.payment.id;
      return newState;
    case "CHECKEDPAYMENT":
      newState.checkedStatus = action.payment.status;
      return newState;
    default:
      return state;
  }
};
type ActionTypes = InferActionTypes<typeof actions>;

export let actions = {
  onGetPayment: (payment: PaymentType) => {
    return { payment, type: "GETPAYMENT" } as const;
  },
  onCheckPayment: (payment: ConfirmPaymentType) => {
    return { payment, type: "CHECKEDPAYMENT" } as const;
  },
  // onGetTutorials: (tutorials: Array<TutorialType>) => {
  //   return { tutorials, type: "GETTUTORIALS" } as const;
  // },
  // onDeletePhotoById: (_id: string) => {
  //   return { _id, type: "DELETEPHOTOBYID" } as const;
  // },
};
