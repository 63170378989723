import { useEffect } from "react";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router";

import { getConfirmationUrl } from "../../selectors/selectors";

export const CreatePayment = () => {
  const confirmationUrl = useSelector(getConfirmationUrl);
  // const navigate = useNavigate();
  // const goYooKassa = () => navigate(confirmationUrl);
  useEffect(() => {
    document.title = "Переход на платежный терминал";
  }, []);
  useEffect(() => {
    if (confirmationUrl)
      setTimeout(() => window.location.replace(confirmationUrl), 2000); //() =>
  }, [confirmationUrl]);
  return (
    <div className="attention">
      <h1>Сейчас Вы будете перенаправлены на платежный терминал для оплаты.</h1>
      <p>
        После подтверждения оплаты приобретенный видеокурс появится в личном
        кабинете на сайте.
      </p>
    </div>
  );
};
