import React, { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { userPostFetch } from "../../utils/API/connectAuth";
import s from "./Signup.module.css";

import { getCurrentUser } from "../../selectors/selectors";
import { useNavigate } from "react-router-dom";
//import { Navigate, useLocation } from "react-router";

export default function Signup() {
  let navigate = useNavigate();
  const { register, formState, handleSubmit, watch, getValues, control } =
    useForm({ mode: "onBlur" });
  const watchPassword = watch("password", "");
  const password = useRef({});
  const { ref, ...rest } = register("password");

  const onSubmit = (data: any) => {
    dispatch(
      userPostFetch({
        email: data.email,
        username: data.username,
        password: data.password,
      })
    );
  };

  const currentUser = useSelector(getCurrentUser);
  useEffect(() => {
    if (currentUser._id) navigate("/tutorials");
  }, [currentUser._id]);

  const dispatch = useDispatch();

  return (
    <div className={s.registrationform}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1>Регистрация нового пользователя</h1>
        <label>
          Адрес электронной почты
          <input
            type="email"
            placeholder="e-mail"
            {...register("email", {
              required: true,
              minLength: { value: 6, message: "Не короче 6 символов" },
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Формат записи электронной почты не соблюден",
              },
            })}
          />
          {formState.errors?.email && (
            <p>{formState.errors?.email?.message || "Обязательный реквизит"}</p>
          )}
        </label>
        <label>
          Имя пользователя Instagram
          <input
            type="text"
            placeholder="Имя пользователя Instagram"
            {...register("username", {
              required: true,
              minLength: { value: 3, message: "Не короче 3 символов" },
            })}
          />
          {formState.errors?.username && (
            <p>
              {formState.errors?.username?.message || "Обязательный реквизит"}
            </p>
          )}
        </label>
        <label>
          Пароль
          <input
            type="password"
            placeholder="Пароль"
            {...register("password", {
              required: true,
              minLength: { value: 6, message: "Не короче 6 символов" },
            })}
          />
          {formState.errors?.password && (
            <p>
              {formState.errors?.password?.message || "Обязательный реквизит"}
            </p>
          )}
        </label>
        <label>
          Повторите пароль
          <input
            type="password"
            {...register("password_repeat", {
              required: true,
              minLength: { value: 6, message: "Не короче 6 символов" },
              validate: (value) =>
                value === watchPassword || "Пароли не совпадают",
            })}
          />
          {formState.errors.password_repeat && (
            <p>{formState.errors.password_repeat.message}</p>
          )}
        </label>
        <input type="submit" />
      </form>
    </div>
  );
}
