import { useState, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { OffersType, PayButtonsType } from "../../types/types";
import { actions } from "../../reducers/orderReducer";
import { getCurrentUser, getTutorials } from "../../selectors/selectors";
import { ShowConfirmInModal } from "./Modal";
//import Modal from '../common/Modal'

type TutorialItemPropsType = OffersType;

export const TutorialItem: FC<TutorialItemPropsType> = (props) => {
  let [showConfirm, setShowConfirm] = useState(false);
  const descriptionItems = props.tutorialDescriptionItems.map((item) => {
    if (!item.shortName) return null;
    return (
      <li key={item.shortName} className="description-item">
        <h3>{item.subTitle}</h3>
        <p>{item.subDescription}</p>
      </li>
    );
  });
  const myTutorials = useSelector(getTutorials);
  const currentUser = useSelector(getCurrentUser);
  let payButtons;

  if (myTutorials.filter((tutorial) => tutorial._id === props._id).length) {
    payButtons = (
      <Link to={"/tutorial?id=" + props._id}>
        <span className="payed"> Уже куплен! Перейти ➡</span>
      </Link>
    );
  } else
    payButtons = props.payButtons.map((item) => {
      if (item._id === "") return null;
      return (
        <li key={props._id}>
          <PayButton
            setShowConfirm={setShowConfirm}
            currentUser={currentUser}
            buttonPrev={item.buttonPrev}
            payButtonLinkUrl={item.payButtonLinkUrl}
            tutorialTitle={props.tutorialTitle}
            tutorialId={props._id}
            tutorialDiscountPrice={props.tutorialDiscountPrice}
            _id={item._id}
          />
        </li>
      );
    });

  const tutorialPictures = props.tutorialPictures.map((item, i) => {
    if (item.src === "") return null;
    return (
      <li key={item.src}>
        <div
          className="tutorial-picture"
          style={{
            backgroundImage: `url(${window.location.protocol}//${window.location.hostname}/images/${item.src}) `,
            backgroundSize: "cover",
          }}
        >
          {i > 0 && item.label ? null : (
            <img
              src={`${window.location.protocol}//${window.location.hostname}/images/${item.label}`}
            />
          )}
        </div>
      </li>
    );
  });

  return (
    <>
      {showConfirm && (
        <ShowConfirmInModal show={showConfirm} setShow={setShowConfirm} />
      )}
      <div className="tutorial-container">
        <div className="tutorial-title">
          <h1>{props.tutorialTitle}</h1>
        </div>

        <div className="tutorial-card">
          <div className="leftside">{tutorialPictures}</div>
          <div className="rightside">
            <h2>В составе видеокурса:</h2>
            {descriptionItems}

            <div className="tutorial-description">
              {props.tutorialDescription}
            </div>
            <div className="tutorial-price">
              Стоимость видеокурса:{props.tutorialDiscountPrice} ₽
            </div>
            {payButtons}
            <div className="attention">{props.tutorialAttention}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const PayButton = (props: PayButtonsType) => {
  const dispatch = useDispatch();
  const { tutorialDiscountPrice, tutorialTitle, tutorialId } = props;
  return (
    <>
      <div className="pay-button-prev">{props.buttonPrev}</div>
      {/* <a href={props.payButtonLinkUrl} target="_blank"> */}
      <button
        onClick={() => {
          dispatch(
            actions.onSaveOrder({
              tutorialDiscountPrice,
              tutorialTitle,
              tutorialId,
            })
          );
          props.setShowConfirm(true);
        }}
        className="pay-button"
      >
        Оплатить
      </button>
    </>
  );
};

export default TutorialItem;
