import { FC } from "react";
import { Link } from "react-router-dom";
import { TutorialDocumentsType, TutorialPicturesType } from "../../types/types";
type TutorialItemType = {
  _id: string;
  tutorialTitle: string;
  tutorialPictures: Array<TutorialPicturesType>;
  videoUrls: Array<string>;
  tutorialDocuments: Array<TutorialDocumentsType>;
};
const TutorialItem: FC<TutorialItemType> = (props) => {
  const tutorialPictures = props.tutorialPictures.map((item, i) => {
    if (item.src === "") return null;
    return (
      <li key={item.src}>
        <div
          className="tutorial-picture item-picture"
          style={{
            backgroundImage: `url(${window.location.protocol}//${window.location.hostname}:${window.location.port}/images/${item.src}) `,
            backgroundSize: "cover",
          }}
        ></div>
        {/* src={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/images/${item.src}`} /> */}
      </li>
    );
  });
  return (
    <>
      <Link to={`/tutorial?id=${props._id}`}>
        <div className="tutorial-item">
          <div className="item-title">
            <h2>{props.tutorialTitle}</h2>
          </div>
          <ul>{tutorialPictures}</ul>
        </div>
      </Link>
    </>
  );
};

export default TutorialItem;
