import { OffersType, TutorialType } from "../types/types";
import { LoginUserType, UserType } from "../types/types";
import { InferActionTypes } from "../reduxStore";
import { Dispatch } from "redux";

type ActionTypes = InferActionTypes<typeof actions>;
type DispatchType = Dispatch<ActionTypes>;

const defaultstate = {
  tutorials: [
    // {
    //   _id: "1",
    //   tutorialTitle: 'Урок "Букет Безе"',
    //   tutorialAttention:
    //     "Не забудьте указать e-mail для получения ссылки доступа к видео-уроку.",
    //   tutorialPictures: [{ _id: 1111, src: "buket_beze.jpg", label: "-5.png" }],
    //   tutorialPrice: 890,
    //   tutorialDiscountPrice: 690,
    //   payButtons: [
    //     {
    //       _id: "11",
    //       buttonPrev: "Оплата Яндекс.Кошелёк, Visa, MasterCard, Maestro",
    //       payButtonLinkUrl: "https://money.yandex.ru/to/4100111978177791",
    //     },
    //     {
    //       _id: "12",
    //       buttonPrev: "Оплата PayPal",
    //       payButtonLinkUrl: "https://paypal.me/puhliykrolikspb?locale.x=ru_RU",
    //     },
    //   ],
    // },

    // {
    //   _id: "2222",
    //   tutorialTitle: 'Урок "Маршболлы"',
    //   tutorialAttention:
    //     "Не забудьте указать e-mail для получения ссылки доступа к видео-уроку.",
    //   tutorialPictures: [
    //     { _id: "11111", src: "marshbolly.jpg", label: "-790.png" },
    //     { _id: "11112", src: "marshbolly2.jpg", label: "-5.png" },
    //   ],

    //   tutorialPrice: 790,
    //   tutorialDiscountPrice: 790,
    //   payButtons: [
    //     {
    //       _id: "11",
    //       buttonPrev: "Оплата Яндекс.Кошелёк, Visa, MasterCard, Maestro",
    //       payButtonLinkUrl: "https://money.yandex.ru/to/4100111978177791",
    //     },
    //     {
    //       _id: "12",
    //       buttonPrev: "Оплата PayPal",
    //       payButtonLinkUrl: "https://paypal.me/puhliykrolikspb?locale.x=ru_RU",
    //     },
    //   ],
    // },
    // {
    //   _id: "618022625051647358c45e6b",
    //   active: true,
    //   tutorialTitle: "Видео Урок❤️Птичье молоко",
    //   tutorialDescription: `
    //   Три новых рецепта, все авторские, все с минимальным содержанием сахара.⠀Урок рассчитан на любой уровень подготовки. Подойдёт и для новичков и для тех, кто отлично разбирается во всех кондитерских премудростях и находится в поисках новых интересных рецептур😉 Урок с постоянным доступом. Цена урока 620₽. `,

    //   tutorialDescriptionItems: [
    //     {
    //       subTitle: "Видео Рецепт Птичье молоко 🍦Пломбир🍦",

    //       subDescription: "Вкус детства, классика по новому😉",
    //     },
    //     {
    //       subTitle: "Видео Рецепт Птичье молоко 🍵ФисташкаЧиз🍵",
    //       subDescription:
    //         "На основе творожного сыра и фисташковой пасты. Нестандартный подход к классическому десерту",
    //     },
    //     {
    //       subTitle: "Видео рецепт Птичье молоко Фламинго",
    //       subDescription:
    //         "Птичье молоко на желатине и клубничном пюре. Невероятная нежность!!!",
    //     },
    //     {
    //       subTitle: "Техкарты всех видео-рецептов, текстовые файлы",
    //       subDescription:
    //         "Файл с ответами на самые популярные вопросы: размер форм, производитель ингредиентов, марка миксера и тд ( будет пополнятся)",
    //     },
    //   ],
    //   tutorialPictures: [
    //     { _id: "11111", src: "moloko.jpg", label: "-620.png" },
    //   ],
    //   tutorialAttention:
    //     "После оплаты все материалы будут открыты в личном кабинете на puhliykrolik.ru. Перед оплатой необходимо авторизоваться на сайте.",
    //   tutorialPrice: 620,
    //   tutorialDiscountPrice: 620,
    //   payButtons: [
    //     {
    //       _id: "11",
    //       buttonPrev: "Оплата Яндекс.Кошелёк, Visa, MasterCard, Maestro",
    //       payButtonLinkUrl: "https://puhliykrolik.ru/api/payment",
    //     },
    //   ],
    // },
    {
      _id: "",
      active: true,
      tutorialTitle: "",
      tutorialDescription: `
      `,

      tutorialDescriptionItems: [
        {
          subTitle: "",

          subDescription: "",
        },
        {
          subTitle: "",
          subDescription: "",
        },
        {
          subTitle: "",
          subDescription: "",
        },
        {
          subTitle: "",
          subDescription: "",
        },
      ],
      tutorialPictures: [{ src: "", label: "" }],
      tutorialAttention: "",
      tutorialPrice: 620,
      tutorialDiscountPrice: 620,
      payButtons: [
        {
          _id: "",
          buttonPrev: "",
          payButtonLinkUrl: "",
        },
      ],
    },
  ] as Array<OffersType>,
};

const tutorialsReducer = (state = defaultstate, action: ActionTypes) => {
  let newState = { ...state };
  switch (action.type) {
    case "GOTTUTORIAL":
      newState.tutorials = [...state.tutorials, action.tutorial];

      return newState;
    case "GOTTUTORIALS":
      newState.tutorials = [...action.tutorials];

      return newState;
    default:
      return state;
  }
};

export let actions = {
  onGetTutorial: (tutorial: TutorialType) => {
    return { type: "GOTTUTORIAL", tutorial } as const;
  },
  onGetTutorials: (tutorials: Array<TutorialType>) => {
    return { type: "GOTTUTORIALS", tutorials } as const;
  },
};

export default tutorialsReducer;
