import { AppStateType } from "./../reduxStore";
export const getCurrentUser = (state: AppStateType) =>
  state.signupPage.currentUser;
export const getTutorials = (state: AppStateType) =>
  state.profilePage.tutorials;
export const getOffers = (state: AppStateType) => state.offersPage.tutorials;
export const getPublicName = (state: AppStateType) =>
  state.profilePage.publicName;
export const getConfirmationUrl = (state: AppStateType) =>
  state.paymentPage.confirmationUrl;
export const getPaymentId = (state: AppStateType) => state.paymentPage.id;
export const getPaymentCheckedStatus = (state: AppStateType) =>
  state.paymentPage.checkedStatus;
export const getError = (state: AppStateType) => state.programPage.error;
export const getSystemMessage = (state: AppStateType) =>
  state.programPage.systemMessage;
export const getModalIsActive = (state: AppStateType) =>
  state.programPage.modalIsActive;
export const getCurrentOrder = (state: AppStateType) => state.orderPage;
