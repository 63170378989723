import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getConfirmationUrl,
  getCurrentUser,
  getOffers,
} from "../../selectors/selectors";
import { getTutorials } from "../../utils/API/connectTutorial";
import { CreatePayment } from "../Payment/CreatePayment";
import TutorialItem from "./TutorialItem";

export const Tutorials = () => {
  const currentUser = useSelector(getCurrentUser);
  const confirmationUrl = useSelector(getConfirmationUrl);
  const tutorials = useSelector(getOffers);

  let dispatch = useDispatch();

  useEffect(() => {
    document.title = "Купить кулинарные видеокурсы от Анны Коваль";
  }, []);
  // useEffect(() => {
  //   dispatch(getProfileFetch());
  // }, []);
  useEffect(() => {
    if (currentUser) {
      //   setProjectsIsFetching(true  )
      dispatch(getTutorials());
      // setProjectsIsFetching(false)
    }
  }, [currentUser, dispatch]);

  // useEffect(() => {
  //   setProjectsIsFetching(true  )
  //   //if (props.currentUser) props.getTutorials(props.currentUser._id)
  //  setProjectsIsFetching(false)
  // }, [props.currentUser._id])

  let tutorialsJsx = [];
  //if (!projectsIsFetching)

  tutorialsJsx = tutorials.map((item) => {
    if (item.active) {
      return (
        // <div key={item._id}>
        <TutorialItem
          _id={item._id}
          active={item.active}
          tutorialDescription={item.tutorialDescription}
          tutorialDescriptionItems={item.tutorialDescriptionItems}
          tutorialTitle={item.tutorialTitle}
          tutorialPictures={item.tutorialPictures}
          tutorialPrice={item.tutorialPrice}
          tutorialDiscountPrice={item.tutorialDiscountPrice}
          payButtons={item.payButtons}
          tutorialAttention={item.tutorialAttention}
        />
        // </div>
      );
    } else return null;
  });

  return (
    <>
      {confirmationUrl && <CreatePayment />}
      <div className="tutorials">{!confirmationUrl && tutorialsJsx}</div>
    </>
  );
};
