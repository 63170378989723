import { actions } from "../../reducers/offersReducer";

export const getTutorials = () => {
  return (dispatch) => {
    let url = `https://puhliykrolik.ru/api/tutorial/`;

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          console.error(data.message);
        } else {
          dispatch(actions.onGetTutorials(data));
        }
      });
  };
};

export const getTutorialById = (id) => {
  return (dispatch) => {
    if (!id || id === "undefined") return null;
    let url = `https://puhliykrolik.ru/api/tutorial/${id}`;

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          console.error(data.message);
        } else {
          dispatch(actions.onGetTutorial(data));
        }
      });
  };
};

export function updateTutorial(file, _id) {
  return (dispatch) => {
    let url = `https://puhliykrolik.ru/api/tutorial/${_id}`;
    return fetch(url, {
      method: "PATCH",
      headers: {
        Authorization: localStorage.token,
      },
      body: file,
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          console.error(data.message);
          //Тут прописываем логику
        } else {
          // localStorage.setItem("token", data.jwt)
          dispatch(actions.onGetTutorial(data));
        }
      });
  };
}

export function updateTutorialPaybutton(file, _id) {
  return (dispatch) => {
    let url = `https://puhliykrolik.ru/api/paybutton/${_id}`;
    return fetch(url, {
      method: "PATCH",
      headers: {
        Authorization: localStorage.token,
      },
      body: file,
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          console.error(data.message);
          //Тут прописываем логику
        } else {
          // localStorage.setItem("token", data.jwt)
          dispatch(actions.onGetTutorial(data));
        }
      });
  };
}

export function tutorialPhotoSend(file, _id) {
  return (dispatch) => {
    if (!_id || _id === "undefined") return null;
    return fetch(`https://puhliykrolik.ru/api/tutorial/photo/${_id}`, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
      },
      body: file,
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          console.error(data.message);
          //Тут прописываем логику
        } else {
          // localStorage.setItem("token", data.jwt)
          dispatch(actions.onGetProfile(data));
        }
      });
  };
}
