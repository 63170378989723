import { Dispatch } from "redux";
import { InferActionTypes } from "../reduxStore";
export type DispatchType = Dispatch<ActionTypes>;

const defaultstate = {
  isFetching: false,
  error: "",
  filter: "",
  modalIsActive: false,
  systemMessage: "",
};
type defaultStateType = typeof defaultstate;

export const programReducer = (state = defaultstate, action: ActionTypes) => {
  let newState = { ...state } as defaultStateType;
  switch (action.type) {
    case "TR_SET_IS_FETCHING":
      newState.isFetching = action.isFetching;
      return newState;
    case "TR_SET_FILTER":
      newState.filter = action.filter;
      return newState;
    case "TR_GOT_ERROR":
      newState.error = action.error.toString();
      return newState;
    case "TR_CLEAR_ERROR":
      newState.error = "";

      return newState;
    case "TR_SET_MODAL_IS_ACTIVE":
      newState.modalIsActive = action.isActive;

      return newState;
    case "TR_SET_SYSTEM_MESSAGE":
      newState.systemMessage = action.message;
      return newState;
    default:
      return state;
  }
};
type ActionTypes = InferActionTypes<typeof actions>;

export let actions = {
  setIsFetching: (isFetching: boolean) => {
    return { isFetching, type: "TR_SET_IS_FETCHING" } as const;
  },
  setFilter: (filter: string) => {
    return { filter, type: "TR_SET_FILTER" } as const;
  },
  gotError: (error: string) => {
    return { error, type: "TR_GOT_ERROR" } as const;
  },
  clearError: () => {
    return { type: "TR_CLEAR_ERROR" } as const;
  },
  setModalIsActive: (isActive: boolean) => {
    return { isActive, type: "TR_SET_MODAL_IS_ACTIVE" } as const;
  },
  setSystemMessage: (message: string) => {
    return { message, type: "TR_SET_SYSTEM_MESSAGE" } as const;
  },
};
