import { FC } from "react";

import JoLPlayer from "jol-player";

type VideoItemType = {
  videoTutorialSrc: string;
  videoWidth:number;
  videoHeight:number;
};
const VideoItem: FC<VideoItemType> = (props) => {
  const videoWidth: number =
    window.screen.width > 750 ? 750 : window.screen.width;
  return (
    <div className="player-wrapper">
      {props.videoTutorialSrc.length > 0 && (
        <JoLPlayer
          option={{
            videoSrc: props.videoTutorialSrc,
            width: videoWidth,
            height: videoWidth*props.videoHeight/props.videoWidth ,
            language: "en",
          }}
        />
      )}
    </div>
  );
};

export default VideoItem;
