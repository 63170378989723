import { LoginUserType, UserType } from "../types/types";
import { InferActionTypes } from "../reduxStore";
import { Dispatch } from "redux";

type ActionTypes = InferActionTypes<typeof actions>;

//type ThunkType = BaseThunkType<ActionTypes>;
type initialStateType = {
  currentUser: UserType;
  hasProfile: boolean;
  active: boolean;
};
const initialState = {
  currentUser: { _id: "", role: "" },
  hasProfile: false,
  active: false,
} as initialStateType;

export default function signupReducer(
  state = initialState,
  action: ActionTypes
) {
  const newState = { ...state };
  switch (action.type) {
    case "LOGIN_USER":
      //if (action.payload.active)
      newState.currentUser = action.payload;
      return newState;
    case "LOGOUT_USER":
      newState.currentUser = { ...state.currentUser };
      newState.currentUser._id = "";
      newState.currentUser.username = "";

      return newState;
    case "ACTIVATED":
      newState.active = true;

      return newState;

    default:
      return state;
  }
}
//******регистрация *******/
type RegisterUserType = {
  email: string;
  username: string;
  password: string;
};

export let actions = {
  loginUser: (userObj: UserType) => {
    return { type: "LOGIN_USER", payload: userObj } as const;
  },
  logoutUser: () => {
    return { type: "LOGOUT_USER" } as const;
  },
  onActivateUser: () => {
    return { type: "ACTIVATED" } as const;
  },
};
