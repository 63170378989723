import { FC } from "react";
import { TutorialDocumentsType } from "../../types/types";

const DocumentItem: FC<TutorialDocumentsType> = (props) => {
  const { title, src } = props;

  return (
    <>
      <div className="tutorial_doc">
        <a href={src}>{title}</a>
      </div>
    </>
  );
};

export default DocumentItem;
