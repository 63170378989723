import { useState, useEffect, FC } from "react";
import { useDispatch } from "react-redux";
import {
  PayButtonsType,
  TutorialDocumentsType,
  TutorialPicturesType,
} from "../../types/types";
import { deletePhotoById } from "../../utils/API/connectProfile";
import {
  updateTutorial,
  updateTutorialPaybutton,
} from "../../utils/API/connectTutorial";
import ImageUpload from "../common/UploadImage";

import s from "./Admin.module.css";

type AdminTutorialItemProps = {
  _id: string;
  tutorialTitle: string;
  tutorialFolder?: string;
  tutorialPrice: number;
  tutorialDiscountPrice: number;
  tutorialAttention: string;
  tutorialPictures: Array<TutorialPicturesType>;
  videoUrls?: Array<string>;
  payButtons: Array<PayButtonsType>;
  tutorialDocuments?: Array<TutorialDocumentsType>;
};

export const AdminTutorialItem: FC<AdminTutorialItemProps> = (props) => {
  const dispatch = useDispatch();
 

  let [tutorialTitle, setTutorialTitle] = useState(props.tutorialTitle);
  let [tutorialPrice, setTutorialPrice] = useState(props.tutorialPrice);
  let [tutorialDiscountPrice, setTutorialDiscountPrice] = useState(
    props.tutorialDiscountPrice
  );
  let [tutorialAttention, setTutorialAttention] = useState(
    props.tutorialAttention
  );
  let [tutorialTitleIsEditing, setTutorialTitleIsEditing] = useState(false);
  let [tutorialPriceIsEditing, setTutorialPriceIsEditing] = useState(false);
  let [tutorialDiscountPriceIsEditing, setTutorialDiscountPriceIsEditing] =
    useState(false);
  let [tutorialAttentionIsEditing, setTutorialAttentionIsEditing] =
    useState(false);

  useEffect(() => {
    if (tutorialTitleIsEditing) {
      (document.getElementById("tutorialTitle") as HTMLFormElement).focus();
    }
  }, [tutorialTitleIsEditing]);
  useEffect(() => {
    if (tutorialPriceIsEditing) {
      (document.getElementById("tutorialPrice") as HTMLFormElement).focus();
    }
  }, [tutorialPriceIsEditing]);
  useEffect(() => {
    if (tutorialDiscountPriceIsEditing) {
      (
        document.getElementById("tutorialDiscountPrice") as HTMLFormElement
      ).focus();
    }
  }, [tutorialDiscountPriceIsEditing]);
  useEffect(() => {
    if (tutorialAttentionIsEditing) {
      (document.getElementById("tutorialAttention") as HTMLFormElement).focus();
    }
  }, [tutorialAttentionIsEditing]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    switch (event.target.name) {
      case "tutorialTitle":
        setTutorialTitle(event.target.value);
        break;
      case "tutorialPrice":
        setTutorialPrice(parseInt(event.target.value));
        break;
      case "tutorialDiscountPrice":
        setTutorialDiscountPrice(parseInt(event.target.value));
        break;
      case "tutorialAttention":
        setTutorialAttention(event.target.value);
        break;
    }
  };

  const Blur = (type: string) => {
    switch (type) {
      case "tutorialTitle":
        setTutorialTitleIsEditing(false);
        break;
      case "tutorialPrice":
        setTutorialPriceIsEditing(false);
        break;
      case "tutorialDiscountPrice":
        setTutorialDiscountPriceIsEditing(false);
        break;
      case "tutorialAttention":
        setTutorialAttentionIsEditing(false);
        break;
    }
    const formData = new FormData();

    formData.append("tutorialTitle", tutorialTitle);
    formData.append("tutorialPrice", String(tutorialPrice));
    formData.append("tutorialDiscountPrice", String(tutorialDiscountPrice));
    formData.append("tutorialAttention", tutorialAttention);

    dispatch(updateTutorial(formData, props._id));
  };

  const tutorialPicturesJsx = props.tutorialPictures.map((item, i) => {
    return (
      <div className="picture">
        <li key={item._id}>
          <img
            src={`${window.location.protocol}//${window.location.hostname}:${window.location.port}/images/${item.src}`}
            className="tutorial_img"
          ></img>
          <button
            title="удалить фото"
            className="delete__button"
            onClick={() => dispatch(deletePhotoById(props._id))}
          ></button>
        </li>
      </div>
    );
  });

  const payButtonsJsx = props.payButtons.map((item) => {
    return (
      <li key={props._id}>
        <PayButtonItem
          tutorialId={props._id}
          _id={item._id}
          buttonPrev={item.buttonPrev}
          payButtonLinkUrl={item.payButtonLinkUrl}
        />
      </li>
    );
  });

  return (
    <>
      <div className="tutorial-admin-card">
        <div className="item-title">
          <h2>{props.tutorialTitle}</h2>
        </div>
        <div className="panel">
          {tutorialTitleIsEditing ? (
            <div className={s.enterdata}>
              <label>Название курса </label>
              <input
                name="tutorialTitle"
                id="tutorialTitle"
                placeholder="Как называется курс"
                value={tutorialTitle}
                onChange={handleChange}
                onBlur={() => Blur("tutorialTitle")}
              />
            </div>
          ) : (
            <div
              className={s.show}
              onClick={() => setTutorialTitleIsEditing(true)}
            >
              <label>Название курса </label>
              <b>{tutorialTitle}</b>
            </div>
          )}
          {tutorialPriceIsEditing ? (
            <div className={s.enterdata}>
              <label>Стоимость курса </label>
              <input
                name="tutorialPrice"
                id="tutorialPrice"
                placeholder="Стоимость курса"
                value={tutorialPrice}
                onChange={handleChange}
                onBlur={() => Blur("tutorialPrice")}
              />
            </div>
          ) : (
            <div
              className={s.show}
              onClick={() => setTutorialPriceIsEditing(true)}
            >
              <label>Стоимость курса</label>
              <b>{tutorialPrice}</b>
            </div>
          )}

          {tutorialDiscountPriceIsEditing ? (
            <div className={s.enterdata}>
              <label>Стоимость курса по акции</label>
              <input
                name="tutorialDiscountPrice"
                id="tutorialDiscountPrice"
                placeholder="Стоимость курса по акции"
                value={tutorialDiscountPrice}
                onChange={handleChange}
                onBlur={() => Blur("tutorialDiscountPrice")}
              />
            </div>
          ) : (
            <div
              className={s.show}
              onClick={() => setTutorialDiscountPriceIsEditing(true)}
            >
              <label>Стоимость курса по акции </label>
              <b>{tutorialDiscountPrice}</b>
            </div>
          )}

          {tutorialAttentionIsEditing ? (
            <div className={s.enterdata}>
              <label>Содержиние поля "внимание:"</label>
              <input
                name="tutorialAttention"
                id="tutorialAttention"
                placeholder='Содержиние поля "внимание:"'
                value={tutorialAttention}
                onChange={handleChange}
                onBlur={() => Blur("tutorialAttention")}
              />
            </div>
          ) : (
            <div
              className={s.show}
              onClick={() => setTutorialAttentionIsEditing(true)}
            >
              <label>Содержиние поля "внимание:"</label>
              <b>{tutorialAttention}</b>
            </div>
          )}
        </div>
        {payButtonsJsx}
        <ImageUpload _id={props._id} />
        <ul>{tutorialPicturesJsx}</ul>
      </div>
    </>
  );
};

type PayButtonItemProps = {
  tutorialId: string;
  _id: string;
  buttonPrev: string;
  payButtonLinkUrl: string;
};

const PayButtonItem: FC<PayButtonItemProps> = (props) => {
  const dispatch = useDispatch();
  let [buttonPrev, setButtonPrev] = useState(props.buttonPrev);
  let [payButtonLinkUrl, setPayButtonLinkUrl] = useState(
    props.payButtonLinkUrl
  );
  let [buttonPrevIsEditing, setButtonPrevIsEditing] = useState(false);
  let [payButtonLinkUrlIsEditing, setPayButtonLinkUrlIsEditing] =
    useState(false);
  useEffect(() => {
    if (buttonPrevIsEditing) {
      (document.getElementById("buttonPrev") as HTMLFormElement).focus();
    }
  }, [buttonPrevIsEditing]);
  useEffect(() => {
    if (payButtonLinkUrlIsEditing) {
      (document.getElementById("payButtonLinkUrl") as HTMLFormElement).focus();
    }
  }, [payButtonLinkUrlIsEditing]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    event.preventDefault();
    switch (event.target.name) {
      case "buttonPrev":
        setButtonPrev(event.target.value);
        break;
      case "payButtonLinkUrl":
        setPayButtonLinkUrl(event.target.value);
        break;
    }
  };

  const Blur = (type: string) => {
    //event.preventDefault()
    switch (type) {
      case "buttonPrev":
        setButtonPrevIsEditing(false);
        break;
      case "payButtonLinkUrl":
        setPayButtonLinkUrlIsEditing(false);
        break;
    }

    const formData = new FormData();

    formData.append("buttonPrev", buttonPrev);
    formData.append("payButtonLinkUrl", payButtonLinkUrl);

    dispatch(updateTutorialPaybutton(formData, props._id));
  };
  return (
    <div className={s.admin_pay_button} key={props._id}>
      <hr></hr>
      {buttonPrevIsEditing ? (
        <div className={s.enterdata}>
          <label>Текст кнопки оплаты</label>
          <input
            name="buttonPrev"
            id="buttonPrev"
            placeholder="Текст кнопки оплаты"
            value={buttonPrev}
            onChange={handleChange}
            onBlur={() => Blur("buttonPrev")}
          />
        </div>
      ) : (
        <div className={s.show} onClick={() => setButtonPrevIsEditing(true)}>
          <label>Текст кнопки оплаты:"</label>
          <b>{buttonPrev}</b>
        </div>
      )}
      {payButtonLinkUrlIsEditing ? (
        <div className={s.enterdata}>
          <label>Куда ссылается кнопка оплаты</label>
          <input
            name="payButtonLinkUrl"
            id="payButtonLinkUrl"
            placeholder="Куда ссылается кнопка оплаты"
            value={payButtonLinkUrl}
            onChange={handleChange}
            onBlur={() => Blur("payButtonLinkUrl")}
          />
        </div>
      ) : (
        <div
          className={s.show}
          onClick={() => setPayButtonLinkUrlIsEditing(true)}
        >
          <label>Куда ссылается кнопка оплаты"</label>
          <b>{payButtonLinkUrl}</b>
        </div>
      )}
      {/* {props._id} {props.buttonPrev}
      {props.payButtonLinkUrl} */}
    </div>
  );
};
