import "./styles/App.css";
import { Header } from "./components/Header/Header";
import { Tutorials } from "./components/Offers/Tutorials";
import ProfileContainer from "./components/Profile/Profile";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import s from "./App.module.css";
import SignUp from "./components/User/Signup";
import Footer from "./components/Footer/Footer";
import { TutorialsGallery } from "./components/Video/TutorialsGallery";
import { TutorialById } from "./components/Video/TutorialById";
import AdminTutorialsContainer from "./components/AdminPanel/AdminTutorials";
import { UserAgreement } from "./components/AddMaterials/UserAgreement";
import { Requisites } from "./components/AddMaterials/Requisites";
import { DelFBAccInstruction } from "./components/AddMaterials/DelFBAccInstruction";
import { ConfPolicy } from "./components/AddMaterials/ConfPolicy";
import { AfterPayment } from "./components/Payment/AfterPayment";
import { ConfirmRegistartion } from "./components/User/ConfirmRegistartion";
import { Login } from "./components/User/Login";
import PleaseConfirmByEmail from "./components/User/PleaseConfirmByEmail";
import NewPassword from "./components/User/NewPassword";
import ForgotPassword from "./components/User/ForgotPassword";
import Error from "./components/Header/Error";

function App() {
  // let context = useContext("store");
  // let { hash } = useParams();
  return (
    <>
      <div className={s.gradient}>
        <div className={s.container}>
          <Router>
            {/*  basename={process.env.PUBLIC_URL} */}

            <>
              <Error />
              <>
                <Header />
              </>
              <div className={s.main}>
                <Routes>
                  <Route path="/tutorial" element={<TutorialById />} />
                  <Route path="/offers" element={<Tutorials />} />
                  <Route path="/" element={<Tutorials />} />
                  <Route path="/gotpayment" element={<AfterPayment />} />
                  <Route path="/useragreement" element={<UserAgreement />} />
                  <Route path="/requisites" element={<Requisites />} />
                  <Route path="/confirm" element={<ConfirmRegistartion />} />
                  <Route path="/newpassword" element={<NewPassword />} />
                  <Route path="/forgotpassword" element={<ForgotPassword />} />
                  <Route
                    path="/please-confirm-by-email"
                    element={<PleaseConfirmByEmail />}
                  />
                  <Route
                    path="/fb-acc-delete-instruction"
                    element={<DelFBAccInstruction />}
                  />
                  <Route path="/conf-politics" element={<ConfPolicy />} />
                  <Route path="/admin" element={<AdminTutorialsContainer />} />
                  <Route path="/signup/" element={<SignUp />} />
                  <Route path="/login/" element={<Login />} />
                  <Route path="/tutorials/" element={<TutorialsGallery />} />
                  <Route path="/profile/" element={<ProfileContainer />} />
                  {/* <Route path="*" element={<Tutorials />} /> */}
                </Routes>
              </div>
            </>

            <div className="footer">
              <Footer />
            </div>
          </Router>{" "}
        </div>
      </div>
    </>
  );
}

export default App;
