import { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getPublicName, getTutorials } from "../../selectors/selectors";
import { TutorialType } from "../../types/types";
import { Navigate, useLocation } from "react-router";

import TutorialItem from "./TutorialItem";
import { getProfileFetch } from "../../utils/API/connectAuth";

export const TutorialsGallery = () => {
  // const currentUser = useSelector(getCurrentUser);
  const tutorials: Array<TutorialType> = useSelector(getTutorials);
  const publicName = useSelector(getPublicName);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfileFetch());
  }, []);
  useEffect(() => {
    document.title =
      "Доступные видеокурсы пользователя " +
      publicName +
      ` на ${process.env.REACT_APP_SITE_TITLE}`;
  }, []);

  const tutorialsJsx = tutorials.map((item) => {
    return (
      <li key={item._id}>
        <TutorialItem
          _id={item._id}
          tutorialTitle={item.tutorialTitle}
          tutorialPictures={item.tutorialPictures}
          videoUrls={item.videoUrls}
          tutorialDocuments={item.tutorialDocuments}
        />
      </li>
    );
  });
  let location = useLocation();
  return (
    <>
      <h1>Доступны для просмотра видеокурсы:</h1>

      {tutorials.length ? (
        <> {tutorialsJsx}</>
      ) : (
        <Navigate to="/offers" state={{ from: location }} />
      )}
    </>
  );
};
