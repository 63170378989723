import { useEffect } from "react";

export const UserAgreement = () => {
  useEffect(() => {
    document.title = "Пользовательское Соглашение puhliykrolik.ru ";
  }, []);

  return (
    <div className="info-text">
      <h1>Пользовательское Соглашение</h1>
      <p>
        Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует
        отношения между владельцем puhliykrolik.ru (далее Видео-уроки от Анны
        Коваль или Администрация) с одной стороны и пользователем сайта с
        другой. Сайт Видео-уроки от Анны Коваль не является средством массовой
        информации.
      </p>

      <p>
        Используя сайт, Вы соглашаетесь с условиями данного соглашения. Если Вы
        не согласны с условиями данного соглашения, не используйте сайт
        Видео-уроки от Анны Коваль!
      </p>

      <h2>Права и обязанности сторон</h2>
      <p>Пользователь имеет право:</p>
      <ul>
        <li> осуществлять поиск информации на сайте</li>
        <li> получать информацию на сайте</li>
        <li> копировать информацию на другие сайты с указанием источника</li>
        <li>
          {" "}
          копировать информацию на другие сайты с разрешения Администрации сайта
        </li>
        <li> использовать информацию сайта в личных некоммерческих целях</li>
      </ul>
      <h2>Администрация имеет право:</h2>
      <ul>
        <li>
          по своему усмотрению и необходимости создавать, изменять, отменять
          правила
        </li>
        <li> ограничивать доступ к любой информации на сайте</li>
        <li> создавать, изменять, удалять информацию</li>
        <li> удалять учетные записи</li>
      </ul>
      <h2>Пользователь обязуется:</h2>
      <ul>
        <li> не нарушать работоспособность сайта</li>
        <li>
          не передавать в пользование свою учетную запись и/или логин и пароль
          своей учетной записи третьим лицам
        </li>
        <li>
          не регистрировать учетную запись от имени или вместо другого лица за
          исключением случаев, предусмотренных законодательством РФ
        </li>
        <li>
          не размещать материалы рекламного, эротического, порнографического или
          оскорбительного характера, а также иную информацию, размещение которой
          запрещено или противоречит нормам действующего законодательства РФ
        </li>
        <li>
          не использовать скрипты (программы) для автоматизированного сбора
          информации и/или взаимодействия с Сайтом и его Сервисами
        </li>
      </ul>
      <h2>Администрация обязуется:</h2>
      <ul>
        <li>
          поддерживать работоспособность сайта за исключением случаев, когда это
          невозможно по независящим от Администрации причинам.
        </li>
      </ul>
      <h2>Ответственность сторон</h2>
      <ul>
        <li>
          администрация не несет никакой ответственности за достоверность
          информации, скопированной из других источников
        </li>
        <li>
          администрация не несёт ответственность за несовпадение ожидаемых
          Пользователем и реально полученных услуг
        </li>
        <li>
          администрация не несет никакой ответственности за услуги,
          предоставляемые третьими лицами
        </li>
        <li>
          в случае возникновения форс-мажорной ситуации (боевые действия,
          чрезвычайное положение, стихийное бедствие и т. д.) Администрация не
          гарантирует сохранность информации, размещённой Пользователем, а также
          бесперебойную работу информационного ресурса
        </li>
      </ul>
      <h2>Условия действия Соглашения</h2>
      <p>
        Данное Соглашение вступает в силу при любом использовании данного сайта.
      </p>
      <p>Соглашение перестает действовать при появлении его новой версии.</p>
      <p>
        Администрация оставляет за собой право в одностороннем порядке изменять
        данное соглашение по своему усмотрению.
      </p>
      <p>Администрация не оповещает пользователей об изменении в Соглашении.</p>
    </div>
  );
};
