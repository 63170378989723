import { useEffect } from "react";

export const Requisites = () => {
  useEffect(() => {
    document.title = "Реквизиты puhliykrolik.ru ";
  }, []);
  return (
    <div className="info-text">
      <h1>Реквизиты puhliykrolik.ru</h1>
      <p>Коваль Анна Васильевна</p> <p>ИНН 100121105339</p>
      <p>
        {" "}
        e-mail:{" "}
        <a href="mailto:admin@puhliykrolik.ru" className="a-link">
          admin@puhliykrolik.ru
        </a>
      </p>
    </div>
  );
};
