import { Link, useSearchParams } from "react-router-dom";
import { getProfileFetch } from "../../utils/API/connectAuth";
import { getCurrentUser } from "../../selectors/selectors";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getProfileByUserId } from "../../utils/API/connectProfile";

import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router";
import HeaderTopMenu from "./HeaderTopMenu";

export function Header() {
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!currentUser._id) dispatch(getProfileFetch());
  }, []);

  useEffect(() => {
    dispatch(getProfileByUserId(currentUser._id));
  }, [currentUser._id]);
  const navigate = useNavigate();

  let [searchParams, setSearchParams] = useSearchParams();
  let hash = searchParams.get("hash") || "";
  useEffect(() => {
    if (currentUser._id && !currentUser.active && !hash)
      navigate("/please-confirm-by-email");
  }, [currentUser, hash, navigate]);

  return (
    <div className="header-upwrapper">
      <div className="header-wrapper">
        <header className="header"></header>
        <Link to="/">
          <div className="logo-wrapper">
            <div className="logo header-element"></div>
          </div>
        </Link>

        <div className="slogan-wrapper">
          <div className="slogan  header-element">
            Видео-уроки от Анны Коваль
          </div>
        </div>
        <div className="menu-wrapper">
          <HeaderTopMenu />
        </div>
      </div>
    </div>
  );
}

export default Header;
