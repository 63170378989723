import { useEffect } from "react";

export const DelFBAccInstruction = () => {
  useEffect(() => {
    document.title =
      ">Инструкция по удалению данных пользователя puhliykrolik.ru ";
  }, []);
  return (
    <div className="info-text">
      <h1>Инструкция по удалению данных пользователя.</h1>
      <p>
        Вы можете запросить удаление всех имеющихся в распоряжении администрации
        сайта данных, полученных при авторизации через Facebook.com, обратившись
        на электронный адрес администрации.
      </p>
    </div>
  );
};
