import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getError, getSystemMessage } from "../../selectors/selectors";
import { sendEmailAgain } from "../../utils/API/connectAuth";

function PleaseConfirmByEmail() {
  const sysMessage = useSelector(getSystemMessage);
  const dispatch = useDispatch();

  const sendConfirmEmail = () => {
    dispatch(sendEmailAgain());
  };
  return (
    <div className="info-text">
      <p>
        Вы зарегистрированы на puhliykrolik.ru, но Ваша учетная запись не
        подтверждена по e-mail. Пожалуйста, проверьте электронную почту, и
        подтвердите регистрацию, перейдя по ссылке в письме. Если Вы не получили
        письмо, оно могло ошибочно попасть в папку "СПАМ" почтового клиента.
      </p>

      {sysMessage ? (
        <Alert variant="success">{sysMessage}</Alert>
      ) : (
        <button onClick={sendConfirmEmail} className="button button-primary">
          Отправить письмо повторно
        </button>
      )}
    </div>
  );
}

export default PleaseConfirmByEmail;
