import React from "react";
import { connect } from "react-redux";
import { AdminTutorialItem } from "./AdminTutorialItem";
import {
  updateTutorialPaybutton,
  updateTutorial,
  getTutorialById,
} from "../../utils/API/connectTutorial";
import {
  //tutorialPhotoSend,
  deletePhotoById,
} from "../../utils/API/connectProfile";
import { AppStateType } from "../../reduxStore";
import { TutorialType } from "../../types/types";

type AdminTutorialsProps = any;

const AdminTutorials: React.FC<AdminTutorialsProps> = (props) => {
  // if (props.currentUser.role !== "superAdmin")
  //   return <Redirect to="/tutorials" />;

  let tutorials = [];

  tutorials = props.tutorials.map((item: TutorialType) => {
    return (
      <div key={item._id}>
        <AdminTutorialItem
          _id={item._id}
          tutorialTitle={item.tutorialTitle}
          tutorialPictures={item.tutorialPictures}
          tutorialPrice={item.tutorialPrice}
          tutorialDiscountPrice={item.tutorialDiscountPrice}
          payButtons={item.payButtons}
          tutorialAttention={item.tutorialAttention}
        />
      </div>
    );
  });

  return (
    <>
      <h1>Административная панель</h1>
      {tutorials}
    </>
  );
};
const mapStateToProps = (state: AppStateType) => {
  return {
    tutorials: state.profilePage.tutorials,
    publicName: state.profilePage.publicName,
    currentUser: state.signupPage.currentUser,
  };
};
const mapDispatchToProps = {
  updateTutorial,
  getTutorialById,
  deletePhotoById,
  //tutorialPhotoSend,
  updateTutorialPaybutton,
};

// type StateType = typeof mapStateToProps;
// type ConnectProps = typeof mapDispatchToProps;
// type OwnProps = any;
// <StateType, ConnectProps, OwnProps>

const AdminTutorialsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminTutorials);

export default AdminTutorialsContainer;
