import React from "react";
import { Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSystemMessage } from "../../selectors/selectors";
import { passwordResend, sendEmailAgain } from "../../utils/API/connectAuth";
import s from "./Signup.module.css";

function ForgotPassword() {
  const sysMessage = useSelector(getSystemMessage);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onBlur" });
  const onSubmit = (data: any) => {
    dispatch(passwordResend(data.email));
  };

  const dispatch = useDispatch();

  // const sendConfirmEmail = () => {
  //   dispatch(sendEmailAgain());
  // };
  return (
    <>
      <div className={s.registrationform}>
        {/* <div className="info-text"> */}

        {/* </div> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1>Восстановление пароля</h1>
          <hr />
          <span>
            Введите электронную почту для восстановления пароля. Если письмо со
            ссылкой для восстановления пароля не приходит, проверьте папку
            "СПАМ".
          </span>
          <label>
            Адрес электронной почты
            <input
              type="email"
              placeholder="e-mail"
              {...register("email", {
                required: true,
                minLength: { value: 6, message: "Не короче 6 символов" },
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: "Формат записи электронной почты не соблюден",
                },
              })}
            />
            {errors?.email && (
              <p>{errors?.email?.message || "Обязательный реквизит"}</p>
            )}
          </label>

          {sysMessage ? (
            <Alert variant="success">{sysMessage}</Alert>
          ) : (
            <input type="submit" />
          )}
        </form>
      </div>
    </>
  );
}

export default ForgotPassword;
