import { TutorialType, ProfileType } from "../types/types";
import { InferActionTypes } from "../reduxStore";
import { isMemberName } from "typescript";

const defaultstate = {
  publicName: "", //- чьи данные профиля загружены
  date: "",
  profileIsFetching: false,
  _id: "",
  tutorials: [
    {
      _id: "",
      active: true,
      tutorialDescription: "",

      tutorialDescriptionItems: [
        {
          subTitle: "",

          subDescription: "",
        },
      ],

      tutorialTitle: "",
      tutorialFolder: "",
      tutorialPrice: 0,
      tutorialDiscountPrice: 0,
      tutorialAttention: "",

      tutorialPictures: [{ src: "", label: "" }],

      videoUrls: [""],
      payButtons: [
        {
          _id: "",
          buttonPrev: "",
          payButtonLinkUrl: "",
        },
      ],
      tutorialDocuments: [
        {
          title: "",
          src: "",
        },
      ],
    },
  ] as Array<TutorialType>,
};

export const profileReducer = (state = defaultstate, action: ActionTypes) => {
  let newState = { ...state };
  switch (action.type) {
    // case DELETEPHOTOBYID:
    //   let _id = action._id;
    //   const index = newState.photos.findIndex((item) => item._id === _id);
    //   const before = newState.photos.slice(0, index);
    //   const after = newState.photos.slice(index + 1);
    //   const newArr = [...before, ...after];
    //   newState.photos = newArr;
    //   return newState;
    case "GETTUTORIALS":
      if (action.tutorials) {
        newState.tutorials = [
          ...action.tutorials.filter((item) => item.active),
        ];
      } else {
        newState.tutorials = [];
      }
      return newState;

    case "GETPROFILE":
      newState.date = action.profile.date;
      newState.publicName = action.profile.publicName;
      newState.profileIsFetching = false;
      newState._id = action.profile._id;
      newState.tutorials = [
        ...action.profile.tutorials.filter((item) => item.active),
      ];

      return newState;

    default:
      return state;
  }
};
type ActionTypes = InferActionTypes<typeof actions>;

export let actions = {
  onGetProfile: (profile: ProfileType) => {
    return { profile, type: "GETPROFILE" } as const;
  },
  onGetTutorials: (tutorials: Array<TutorialType>) => {
    return { tutorials, type: "GETTUTORIALS" } as const;
  },
  onDeletePhotoById: (_id: string) => {
    return { _id, type: "DELETEPHOTOBYID" } as const;
  },
};
