import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userLoginFetch } from "../../utils/API/connectAuth";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../../selectors/selectors";
import s from "./Signup.module.css";
import { useNavigate } from "react-router";

export function Login() {
  let [user, setUser] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ mode: "onBlur" });

  const onSubmit = (data: any) => {
    dispatch(
      userLoginFetch({
        email: data.email,
        password: data.password,
      })
    );
  };
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    navigate("/offers");
    setUser(currentUser._id);
  }, [currentUser._id]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1, { replace: true });
  };
  return (
    <div className={s.registrationform}>
      {user ? (
        <>
          <h1>Вы успешно вошли в систему!</h1>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Вход</h1>
            <label>
              Адрес электронной почты
              <input
                type="email"
                placeholder="e-mail"
                {...register("email", {
                  required: true,
                  minLength: { value: 6, message: "Не короче 6 символов" },
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Формат записи электронной почты не соблюден",
                  },
                })}
              />
              {errors?.email && (
                <p>{errors?.email?.message || "Обязательный реквизит"}</p>
              )}
            </label>

            <label>
              Пароль
              <input
                type="password"
                placeholder="Пароль"
                {...register("password", {
                  required: true,
                  // minLength: { value: 6, message: "Не м.б. короче 6 символов" },
                })}
              />
              {errors?.password && (
                <p>{errors?.password?.message || "Обязательный реквизит"}</p>
              )}
            </label>
            <input type="submit" />
          </form>
          <span>Нет аккаунта?</span>
          <Link to="/signup/">Зарегистрироваться</Link> <br></br>
          <span>Забыли пароль?</span>
          <Link to="/forgotpassword">Восстановить</Link>
        </>
      )}
      <button onClick={goBack}>Вернуться назад</button>
    </div>
  );
}
