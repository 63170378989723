import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getError } from "../../selectors/selectors";
import { actions as programActions } from "../../reducers/programReducer";
import { Alert } from "react-bootstrap";

function Error() {
  let error = useSelector(getError);
  const dispatch = useDispatch();
  const clearError = () => {
    dispatch(programActions.clearError());
  };

  return (
    <>
      {error && (
        <>
          <div className="top-banner">
            <Alert variant="danger" onClose={clearError} dismissible>
              <Alert.Heading>Произошла ошибка:</Alert.Heading>
              <h1>{error}</h1>
            </Alert>
          </div>
        </>
      )}
    </>
  );
}

export default Error;
