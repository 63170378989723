import React, { useState, useEffect } from "react";
import s from "./Profile.module.css";
// import { onAddPosts, onDeletePostById, onAddPostPhoto, onSetPostTitleBody, onSelectTag, onClearPost } from '../../reducers/profileReducer'
import { getProfileFetch } from "../../utils/API/connectAuth";
import { connect } from "react-redux";
// import { clearCommentById, getCommentsByPostId, getPostsByUserId, sendPostComment, deletePostById, likeCommentById, unlikeCommentById, likePostById, unlikePostById } from '../../utils/API/connectPosts'
// import { getSelectedUserProfileByUserId } from '../../utils/API/connectSelectedProfile'
import { getProfileByUserId } from "../../utils/API/connectProfile";

//import Preloader from '../../utils/preloader'
import ProfileAnketaContainer from "./ProfileAnketa";

const Profile = (props) => {
  // let [modalActive, setModalActive] = useState(true)
  // let [addPostState, toggleAddPost] = useState(false)
  // let [postsIsFetching, setPostsIsFetching] = useState(false)
  useEffect(() => {
    document.title =
      "Профиль пользователя " +
      props.publicName +
      ` на ${process.env.REACT_APP_SITE_TITLE}`;
  }, []);

  // if (!props.match.params.userId) {
  //props.getProfileFetch()

  useEffect(() => {
    // setPostsIsFetching(true)

    if (props.currentUser) {
      props.getProfileByUserId(props.currentUser._id);
    }
    //setPostsIsFetching(false)
  }, [props.currentUser._id]);

  useEffect(() => {
    if (props.currentUser) {
      props.getProfileByUserId(props.currentUser._id);
    }
  }, []);

  return (
    <div className={s.profile__top}>
      <h1>Профиль пользователя {props.publicName}</h1>
      <ProfileAnketaContainer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.signupPage.currentUser,
    // posts: state.profilePage.searchedPosts,
    // postComments: state.profilePage.postComments,
    // status: state.profilePage.status,
    // livingPlace: state.profilePage.livingPlace,
    // profileImageSrc: state.profilePage.profileImageSrc,
    // date: state.profilePage.date,
    // publicName: state.profilePage.publicName,
    // postsIsFetching: state.profilePage.postsIsFetching,
    // profileIsFetching: state.profilePage.profileIsFetching,
    // profileId: state.profilePage.profileId,
    // searchedTag: state.tagsPage.searchedTag,
    // selectedUserProfile: state.usersPage.selectedUserProfile,
    // photos: state.profilePage.photos
  };
};

const ProfileContainer = connect(mapStateToProps, {
  getProfileFetch,
  getProfileByUserId,
})(Profile);

// onAddPostPhoto,
//   onAddPosts, sendPostComment, onDeletePostById, getPostsByUserId,getCommentsByPostId, getPhotoByUserId, deletePostById, likeCommentById,
//   unlikeCommentById, likePostById, unlikePostById, onSelectTag, onSetPostTitleBody, onClearPost,
//   getSelectedUserProfileByUserId, clearCommentById

export default ProfileContainer;
