import { actions } from "../../reducers/paymentReducer";

export const createPayment = (value, offerTitle, tutorial, username) => {
  return (dispatch) => {
    const formData = new FormData();

    formData.append("value", value);
    formData.append("offer", offerTitle);
    formData.append("tutorial", tutorial);
    formData.append("username", username);

    // formData.append("tutorialDiscountPrice", String(tutorialDiscountPrice));
    // formData.append("tutorialAttention", tutorialAttention);

    let url = `https://puhliykrolik.ru/api/payment`;
    return fetch(url, {
      method: "POST",
      headers: {
        Authorization: localStorage.token,
      },
      body: formData,
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          console.error(data.message);
        } else {
          dispatch(actions.onGetPayment(data));
        }
      });
  };
};
export const checkPaymentStatusByOfferId = (offerId) => {
  return (dispatch) => {
    if (!offerId || offerId === "undefined") return null;
    let url = "https://puhliykrolik.ru/api/payment/offer/" + offerId;
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.token,
        Accept: "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
        } else {
          dispatch(actions.onCheckPayment(data));
        }
      });
  };
};

export const checkPaymentStatusById = (paymentId) => {
  return (dispatch) => {
    if (!paymentId || paymentId === "undefined") return null;
    let url = "https://puhliykrolik.ru/api/payment/" + paymentId;
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
        } else {
          dispatch(actions.onCheckPayment(data));
        }
      });
  };
};
