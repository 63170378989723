import { useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTutorialById } from "../../utils/API/connectTutorial";
import VideoItem from "./VideoItem";
import DocumentItem from "./DocumentItem";
import {
  getCurrentUser,
  getPublicName,
  getTutorials,
} from "../../selectors/selectors";
import { useSearchParams } from "react-router-dom";
import { JsxFragment } from "typescript";

export const TutorialById: FC<any> = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let tutorialId = searchParams.get("id");

  const tutorials = useSelector(getTutorials);
  const publicName = useSelector(getPublicName);
  //const currentUser = useSelector(getCurrentUser);
  // const dispatch = useDispatch();

  //   useEffect(()=> {
  //     if (props.selectedUser) {
  //       props.getTutorialById(props.curentUser)
  //     }},[props.selectedUser]
  //   )

  // useEffect(() => {
  //   dispatch(getTutorialById(tutorialId));
  // }, [tutorialId]);

  useEffect(() => {
    if (temp[0])
      document.title = `Видеокурс ${temp[0].tutorialTitle} на ${process.env.REACT_APP_SITE_TITLE}`;
  }, [publicName]);

  const temp = tutorials.filter((i) => i._id === tutorialId);
  let videoTutorials: JSX.Element[] = [];
  let tutorialDocuments: JSX.Element[] = [];
  if (temp.length) {
    videoTutorials = temp[0].videoUrls.map((item) => {
      const url = `https://puhliykrolik.ru/video/${temp[0].tutorialFolder}/${item}`;
      return (
        <li key={item}>
          <VideoItem videoTutorialSrc={url} videoHeight={temp[0].videoHeight} videoWidth={temp[0].videoWidth}/>
        </li>
      );
    });

    tutorialDocuments = temp[0].tutorialDocuments.map((item) => {
      return (
        <li key={item.src}>
          <DocumentItem
            title={item.title}
            src={`https://puhliykrolik.ru/video/${temp[0].tutorialFolder}/${item.src}`}
          />
        </li>
      );
    });
  }
  return (
    <div className="tutorials-list">
      <h3>Видеофайлы курса</h3>
      <ul>{videoTutorials}</ul>
      <h3>Документы к курсу</h3>
      <ul>{tutorialDocuments}</ul>
    </div>
  );
};
