import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  getPaymentId,
  getPaymentCheckedStatus,
  getCurrentUser,
} from "../../selectors/selectors";
import { getProfileFetch } from "../../utils/API/connectAuth";
import { checkPaymentStatusByOfferId } from "../../utils/API/connectPayment";

export const AfterPayment = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let offerId = searchParams.get("offer");

  let id = useSelector(getPaymentId);
  let currentUser = useSelector(getCurrentUser);
  const status = useSelector(getPaymentCheckedStatus);

  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUser._id) {
      dispatch(checkPaymentStatusByOfferId(offerId));
    }
  }, [offerId, currentUser._id]);

  useEffect(() => {
    document.title = ">Спасибо за покупку на puhliykrolik.ru ";
  }, []);

  let [reason, setReason] = useState(
    "Приобретенный видеокурс появится в разделе 'Мои курсы' на сайте."
  );
  useEffect(() => {
    switch (status) {
      case "pending":
        setReason(
          "Заказ еще не оплачен. Если вы оплатили заказ, обратите внимание, что банк еще не передал информацию об успехе оплаты. Проверка статуса будет выполнена автоматически через 1 минуту."
        );
        break;
      case "canceled":
        setReason(
          "Заказ не оплачен. В процессе оплаты произошла ошибка в платежной системе. Средства были возвращены на ваш счет. Если информация об этом не отобразилась в ЛК банка или нет смс о возврате средств, пожалуйста, подождите. Возврат средств может занять до трех дней."
        );
        break;
      case "succeeded":
        setReason(
          "Поздравляем! Заказ оплачен. Банк подтвердил перевод денег, и заказ появился в Вашем личном кабинете."
        );
        break;
      case "waiting_for_capture":
        setReason(
          "Поздравляем! Заказ оплачен. Банк подтвердил перевод денег, и заказ появился в Вашем личном кабинете."
        );
        break;
    }
    dispatch(getProfileFetch());
  }, [status]);

  return (
    <div className="info">
      <h1>Спасибо за покупку на puhliykrolik.ru!</h1>
      <p>{reason}</p>
    </div>
  );
};
