import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <li>
        <p className="link-dark">&copy;2021-2022</p>
      </li>
      <li>
        <Link to="/useragreement" className="link-dark">
          Пользовательское соглашение
        </Link>
      </li>
      <li>
        <Link to="/requisites" className="link-dark">
          Реквизиты продавца
        </Link>
      </li>
      <li>
        <small>
          <Link to="/fb-acc-delete-instruction" className="link-dark">
            Инструкция по удалению сведений пользователя
          </Link>
        </small>
      </li>
      <li>
        <Link to="/conf-politics" className="link-dark">
          Политика конфиденциальности
        </Link>
      </li>
      <li>
        <p className="link-dark">Создание сайта:</p>
        <a href="https://t.me/Vanich007" className="link-dark">
          https://t.me/Vanich007
        </a>
      </li>
    </>
  );
}

export default Footer;
