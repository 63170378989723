import React, { FC } from "react";
import { Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentOrder, getCurrentUser } from "../../selectors/selectors";
import { createPayment } from "../../utils/API/connectPayment";
import { Login } from "../User/Login";

function Confirmation() {
  const order = useSelector(getCurrentOrder);
  const currentUser = useSelector(getCurrentUser);
  let dispatch = useDispatch();
  return (
    <>
      Вы собираетесь оплатить курс{" "}
      <Badge bg="primary">{order.tutorialTitle}</Badge> стоимостью{" "}
      <Badge bg="primary">{order.tutorialDiscountPrice} Р</Badge>. Оплата
      производится через сервис Яндекс.Касса (Yookassa).
      {currentUser._id ? (
        <button
          onClick={() =>
            dispatch(
              createPayment(
                order.tutorialDiscountPrice,
                order.tutorialTitle,
                order.tutorialId,
                currentUser.username
              )
            )
          }
          className="pay-button"
        >
          Оплатить
        </button>
      ) : (
        <Login />
      )}
    </>
  );
}

export default Confirmation;
