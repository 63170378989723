import { programReducer } from "./reducers/programReducer";
import { profileReducer } from "./reducers/profileReducer";
import signupReducer from "./reducers/signupReducer";
import offersReducer from "./reducers/offersReducer";
import { orderReducer } from "./reducers/orderReducer";
// import videoReducer from "./reducers/videoReducer";
import { applyMiddleware, combineReducers, createStore } from "redux";
//import { reducer as formReducer } from "redux-form";
import thunkMiddleware from "redux-thunk";
import { paymentReducer } from "./reducers/paymentReducer";
let reducers = combineReducers({
  programPage: programReducer,
  orderPage: orderReducer,
  profilePage: profileReducer,
  signupPage: signupReducer,
  offersPage: offersReducer,
  paymentPage: paymentReducer,
  //form: formReducer,
});

type RootReducerType = typeof reducers;

type InferValueTypes<T> = T extends { [key: string]: infer U } ? U : never;

//type actions = profileAC;
export type InferActionTypes<
  T extends { [key: string]: (...args: any[]) => any }
> = ReturnType<InferValueTypes<T>>;

export type AppStateType = ReturnType<RootReducerType>;
let store = createStore(reducers, applyMiddleware(thunkMiddleware));

export default store;
