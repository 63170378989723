import { actions as signUpActions } from "../../reducers/signupReducer";
import { actions as programActions } from "../../reducers/programReducer";
import { actions as profileActions } from "../../reducers/profileReducer";

import { getProfileByUserId } from "./connectProfile";
// import { LoginUserType, UserType } from "../../types/types";
// import { InferActionTypes } from "../../reduxStore";
// import { Dispatch } from "redux";

export const updatePassword = (obj) => {
  return (dispatch) => {
    return fetch(`https://puhliykrolik.ru/api/auth/updatepassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          //Тут прописываем логику
        } else if (data.sysMessage) {
          dispatch(programActions.setSystemMessage(data.sysMessage));
        } else {
          dispatch(signUpActions.loginUser(data));
          getProfileByUserId(data._id);
        }
      })
      .catch((error) => dispatch(programActions.gotError(error)));
  };
};

export const passwordResend = (email) => {
  return (dispatch) => {
    return fetch(`https://puhliykrolik.ru/api/auth/resendpassword/${email}`, {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.token,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          dispatch(programActions.gotError(data.message));
        } else if (data.sysMessage) {
          dispatch(programActions.setSystemMessage(data.sysMessage));
        } else {
          // dispatch(signUpActions.loginUser(data));
          // getProfileByUserId(data._id);
        }
      })
      .catch((error) => dispatch(programActions.gotError(error)));
  };
};

export const userPostFetch = (user) => {
  return (dispatch) => {
    return fetch("https://puhliykrolik.ru/api/auth/register", {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          dispatch(programActions.gotError(data.message));
        } else {
          localStorage.setItem("token", data.jwt);
          dispatch(signUpActions.loginUser(data.user));
          // getProfileByUserId(data.user._id);
        }
      })
      .catch((error) => dispatch(programActions.gotError(error)));
  };
};

export const checkHashFromEmail = (hash) => {
  return (dispatch) => {
    return fetch(`https://puhliykrolik.ru/api/confirm/checkhash/${hash}`, {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ hash }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          dispatch(programActions.gotError(data.message));
        } else if (data.sysMessage) {
          dispatch(programActions.setSystemMessage(data.sysMessage));
        } else {
          dispatch(signUpActions.loginUser(data.user));
          dispatch(profileActions.onGetProfile(data.newprofile));
        }
      })
      .catch((error) => dispatch(programActions.gotError(error)));
  };
};

export const sendEmailAgain = () => {
  return (dispatch) => {
    return fetch(`https://puhliykrolik.ru/api/confirm/sendemail`, {
      method: "GET",

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: localStorage.token,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          dispatch(programActions.gotError(data.message));
        } else if (data.sysMessage) {
          dispatch(programActions.setSystemMessage(data.sysMessage));
        } else {
        }
      })
      .catch((error) => dispatch(programActions.gotError(error)));
  };
};
//залогиниваемся*********************************************************
export const userLoginFetch = (user) => {
  return (dispatch) => {
    return fetch("https://puhliykrolik.ru/api/auth/login", {
      method: "POST",
      //mode: 'no-cors',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(user),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          dispatch(programActions.gotError(data.message));
        } else {
          localStorage.setItem("token", data.jwt);
          //localStorage.setItem("user", user)
          dispatch(signUpActions.loginUser(data.user));
          getProfileByUserId(data.user._id);
        }
      })
      .catch((error) => dispatch(programActions.gotError(error)));
  };
};
//логин по token***************************************
export const getProfileFetch = () => {
  return (dispatch) => {
    const token = localStorage.token;
    if (token) {
      return fetch("https://puhliykrolik.ru/api/auth/register", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${token}`,
        },
      })
        .then((resp) => {
          if (resp.status >= 400) {
            throw new Error(resp.status);
          } else return resp.json();
        })
        .then((data) => {
          if (data) {
            if (data.message || data === "Unauthorized") {
              console.error(data.message);
              // Будет ошибка если token не дествительный
              localStorage.removeItem("token");
              dispatch(signUpActions.logoutUser());
            } else {
              dispatch(signUpActions.loginUser(data.user));
            }
          }
        })
        .catch((error) => {
          dispatch(programActions.gotError(error));
        });
    }
  };
};
