import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { checkHashFromEmail } from "../../utils/API/connectAuth";
import { getCurrentUser } from "../../selectors/selectors";
import { Login } from "./Login";
import { Navigate, useLocation } from "react-router";

export const ConfirmRegistartion: FC = (props: any) => {
  let [searchParams, setSearchParams] = useSearchParams();
  let hash = searchParams.get("hash") || "";
  let dispatch = useDispatch();
  let currentUser = useSelector(getCurrentUser);
  useEffect(() => {
    if (currentUser.active)
      <Navigate to="/tutorials/" state={{ from: location }} />;
    // currentUser.active && <Login />
  }, [currentUser, currentUser.active]);

  useEffect(() => {
    dispatch(checkHashFromEmail(hash));
  }, [hash]);
  let location = useLocation();
  return (
    <>
      <h1>Направлен запрос на подтверждение учетной записи</h1>
      {!currentUser.active && (
        <p>Ждем ответа сервера по активации пользователя.</p>
      )}
      )
    </>
  );
};
