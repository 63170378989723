import { actions } from "../../reducers/profileReducer";

export const deletePhotoById = (photoId) => {
  return (dispatch) => {
    let url = "https://puhliykrolik.ru/api/profile/photo/" + photoId;
    return fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          console.error(data.message);
        } else {
          dispatch(actions.onDeletePhotoById(data.deleted));
        }
      });
  };
};

export const getProfileByUserId = (userId) => {
  return (dispatch) => {
    if (!userId || userId === "undefined") return null;
    let url = "https://puhliykrolik.ru/api/profile/" + userId;
    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
        } else {
          dispatch(actions.onGetProfile(data));
          //getPostsByUserId(data.user)
        }
      });
  };
};

export const updateProfile = (user) => {
  return (dispatch) => {
    let url = "https://puhliykrolik.ru/api/profile";
    return fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.token,
      },
      body: JSON.stringify(user),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          //Тут прописываем логику
        } else {
          dispatch(actions.onGetProfile(data));
        }
      });
  };
};

export function userProfileSend(file) {
  return (dispatch) => {
    let url = "https://puhliykrolik.ru/api/profile";
    return fetch(url, {
      method: "PATCH",
      headers: {
        Authorization: localStorage.token,
      },
      body: file,
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.message) {
          console.error(data.message);
          //Тут прописываем логику
        } else {
          // localStorage.setItem("token", data.jwt)
          dispatch(actions.onGetProfile(data));
        }
      });
  };
}
