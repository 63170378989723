import { InferActionTypes } from "../reduxStore";

const defaultstate = {
  tutorialDiscountPrice: 0,
  tutorialTitle: "",
  tutorialId: "",
};
type OrderType = typeof defaultstate;

export const orderReducer = (
  state: OrderType = defaultstate,
  action: ActionTypes
) => {
  let newState = { ...state };
  switch (action.type) {
    case "SAVEORDER":
      newState.tutorialDiscountPrice = action.order.tutorialDiscountPrice;
      newState.tutorialTitle = action.order.tutorialTitle;
      newState.tutorialId = action.order.tutorialId;
      return newState;

    default:
      return state;
  }
};
type ActionTypes = InferActionTypes<typeof actions>;

export let actions = {
  onSaveOrder: (order: OrderType) => {
    return { order, type: "SAVEORDER" } as const;
  },
};
