// import React, { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { updatePassword, userPostFetch } from "../../utils/API/connectAuth";
import s from "./Signup.module.css";

import { getCurrentUser } from "../../selectors/selectors";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
//import { Navigate, useLocation } from "react-router";

export default function NewPassword() {
  const currentUser = useSelector(getCurrentUser);
  const { register, formState, handleSubmit, watch, getValues, control } =
    useForm({ mode: "onBlur" });
  const watchPassword = watch("password", "");

  const navigate = useNavigate();

  if (currentUser._id) navigate("/offers");

  useEffect(() => {
    document.title = ">Введите Ваш новый пароль на puhliykrolik.ru ";
  }, []);

  let [searchParams, setSearchParams] = useSearchParams();
  let hash = searchParams.get("hash") || "";

  let dispatch = useDispatch();

  const onSubmit = (data: any) => {
    dispatch(
      updatePassword({
        hash,
        password: data.password,
      })
    );
  };

  //const currentUser = useSelector(getCurrentUser);

  return (
    <div className={s.registrationform}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1>Введите новый пароль</h1>

        <label>
          Новый пароль
          <input
            type="password"
            placeholder="Пароль"
            {...register("password", {
              required: true,
              minLength: { value: 6, message: "Не короче 6 символов" },
            })}
          />
          {formState.errors?.password && (
            <p>
              {formState.errors?.password?.message || "Обязательный реквизит"}
            </p>
          )}
        </label>
        <label>
          Повторите пароль
          <input
            type="password"
            {...register("password_repeat", {
              required: true,
              minLength: { value: 6, message: "Не короче 6 символов" },
              validate: (value) =>
                value === watchPassword || "Пароли не совпадают",
            })}
          />
          {formState.errors.password_repeat && (
            <p>{formState.errors.password_repeat.message}</p>
          )}
        </label>
        <input type="submit" />
      </form>
    </div>
  );
}
